import { Field, Form, Formik } from "formik";
import * as React from "react";
import { Head } from "../components/Header/head";
import { Navbar } from "../components/Navbar/navbar";
import "../styles/contact.css";
import * as Yup from "yup";
import Footer from "../components/Footer/footer";
import { SSRProvider } from "@react-aria/ssr";

const contactSchema = Yup.object().shape({
  email: Yup.string().email("Email invalido").required("Requerido"),
  name: Yup.string().required("Requerido"),
  telephone: Yup.number().required("Requerido").positive().integer(),
  subject: Yup.string().required("Requerido"),
  message: Yup.string().required("Requerido"),
});
//b25fa246-e718-43e5-b51d-9636c2d4e043
//89a71b25-ac92-4a38-a0a6-9a9b1e8fdf78 funciona
const ContactPage = () => {
  const handleSubmit = (formValues) => {
    typeof window !== "undefined" &&
      window.Email.send({
        SecureToken: "c187729f-a27e-44b5-bce5-90d396964f90",
        To: "losjeronimos@gmail.com",
        From: "contactolosjeronimos@gmail.com",
        Subject: formValues.subject,
        Body:
          "<html> Hola, soy: " +
          formValues.name +
          " <br><br> Mi email es: <strong>" +
          formValues.email +
          "</strong> y mi télefono: <strong>" +
          formValues.telephone +
          "</strong> <br> Os escribo a través de vuestra página web y mi mensaje es el siguiente : <br><br> <strong>" +
          formValues.message +
          "</strong><br></br></html>",
      }).then((message) => {
        console.log(message);
      });
  };

  return (
    <SSRProvider>
      <Head title="Posada Los Jerónimos - Contacto" />
      <Navbar transparent />
      <section className="contact" id="title">
        <h1>Contacto</h1>
      </section>
      <section id="contact-cards" className="cards">
        <div className="card-wrapper">
          <div className="card">
            <div className="card-content">
              <div className="logo">
                <i className="bi bi-geo-alt"></i>
              </div>
              <h2 className="card-title"> Localización</h2>
              <p> C\ Peñas la Tuda, 49173 La Tuda, Zamora</p>
            </div>
          </div>
          <div className="card">
            <div className="card-content">
              <div className="logo">
                <i className="bi bi-telephone"></i>
              </div>
              <h2 className="card-title"> Teléfono</h2>
              <p> 980 55 12 40</p>
              <p> 699 41 16 64</p>
            </div>
          </div>
          <div className="card">
            <div className="card-content">
              <div className="logo">
                <i className="bi bi-envelope"></i>
              </div>
              <h2 className="card-title"> Correo</h2>
              <p>
                <a href="mailto:losjeronimos@gmail.com">losjeronimos@gmail.com</a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="maps" className="maps">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2993.6181030245566!2d-5.859728984273161!3d41.38238125426785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd392377913c8689%3A0x9a5f9261207fc4ab!2sLos%20Jer%C3%B3nimos!5e0!3m2!1ses!2ses!4v1618693307284!5m2!1ses!2ses"
          width="600"
          height="450"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </section>
      <section id="answer" className="answer">
        <div className="answer-wrapper">
          <h2>¿TIENES ALGUNA PREGUNTA?</h2>
        </div>
      </section>
      <Formik
        initialValues={{
          name: "",
          email: "",
          message: "",
          telephone: "",
          subject: "",
        }}
        validationSchema={contactSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {({ errors, touched, dirty, isValid, re }) => (
          <section id="form-section" className="form">
            <Form className="form-wrapper" noValidate>
              <div className="input-wrapper">
                <div>
                  <label htmlFor="name">Nombre*: </label>
                  <Field name="name" className="form-control" />
                  {errors.name && touched.name && (
                    <div className="input-error">{errors.name}</div>
                  )}
                </div>
                <div>
                  <label htmlFor="email">Email*: </label>
                  <Field name="email" className="form-control" />
                  {errors.email && touched.email && (
                    <div className="input-error">{errors.email}</div>
                  )}
                </div>
                <div>
                  <label htmlFor="telephone">Teléfono*: </label>
                  <Field
                    name="telephone"
                    type="number"
                    className="form-control"
                  />
                  {errors.telephone && touched.telephone && (
                    <div>{errors.telephone}</div>
                  )}
                </div>
                <div>
                  <label htmlFor="subject">Asunto*: </label>
                  <Field name="subject" className="form-control" />
                  {errors.subject && touched.subject && (
                    <div className="input-error">{errors.subject}</div>
                  )}
                </div>
              </div>
              <label htmlFor="message">Mensaje*: </label>
              <Field
                name="message"
                component="textarea"
                className="form-control"
              />
              {errors.message && touched.message && (
                <div className="input-error">{errors.message}</div>
              )}

              <button type="submit" disabled={!(dirty && isValid)}>
                Enviar
              </button>
            </Form>
          </section>
        )}
      </Formik>
      <Footer slim />
    </SSRProvider>
  );
};

export default ContactPage;
